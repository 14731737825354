.slider-container {
  /* width: 200px */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.range-slider {
  -webkit-appearance: none;
  width: 90%;
  height: 30px;
  border-radius: 30px;
  background: linear-gradient(
    0.25turn,
    rgb(187, 187, 187),
    55%,
    rgb(255, 255, 255)
  );
  outline: none;
  border: solid #d6d6d6 2px;
  padding: 0;
  margin-top: 4px;
  box-shadow: 1px 1px 1px rgb(179, 179, 179);
  /* margin-bottom: 19px; */
  margin-right: 10px;
}

.range-slider::-webkit-slider-thumb {
  appearance: none;
  width: 28px;
  height: 28px;
  border: solid #072b62 2px;
  background-color: white;
  border-radius: 50%;
  cursor: pointer;
  transition: background 0.15s ease-in-out;
}

.range-slider::-webkit-slider-thumb:active {
  appearance: none;
  width: 37px;
  height: 37px;
  border: solid #072b62 2px;
  background-color: white;
  border-radius: 50%;
  cursor: pointer;
  transition: background 0.15s ease-in-out;
}

.range-slider-value {
  display: block;
  position: fixed;
  bottom: 6.5rem;
  left: 0;
  width: 100vw;
  color: #fff;
  font-size: 0.9em;
  font-weight: 700;
  text-align: center;
  text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.4);
}

.range-slider-value:after {
  position: absolute;
  top: 8px;
  left: -7px;
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  content: "";
}
