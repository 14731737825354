.svg-icon-btn-rnd {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  align-items: center;
  border: none;
  background-color: #fafafa;
  cursor: pointer;
  box-shadow: 2px 2px 2px #dbdbd9;
  font-size: 14px;
  opacity: 80%;
  z-index: 265;
}

.svg-icon-btn-rnd:active {
  background-color: #bcd2e8;
}
